'use client';

import { useParams, usePathname } from 'next/navigation';
import React from 'react';
import { Dropdown, DropdownProps } from 'react-bootstrap';
import { Locale, i18n as i18nConfig } from '../../../i18n-config';
import { useTranslation } from '@/i18n/client';
import Link from 'next/link';
import Image from 'next/image';
import { BiCaretDown } from 'react-icons/bi';
import { useRouter } from 'next/navigation';

const LanguageMapper = {
  'en-US': 'English',
  'fr-FR': 'Français',
} as { [key: string]: string };

const LanguageChangeDropdown = ({
  placement = 'bottom',
  id,
}: {
  placement?: DropdownProps['placement'] | undefined;
  id: string;
}) => {
  const router = useRouter();

  const params = useParams();
  const pathName = usePathname();
  const { i18n } = useTranslation(params.lang as Locale);

  const selectHandler = (locale: string) => {
    if (!pathName) return '/en-US';
    const segments = pathName.split('/');
    segments[1] = locale;
    return segments.join('/');
  };

  const handleClick = (url: string) => {
    router.push(url);
  };

  const lngFlagMapper = {
    'en-US': 'https://cdn.1cdn.app/application/UMD/24041507435389_US.png',
    'fr-FR': 'https://cdn.1cdn.app/application/UMD/24041507435387_France.png',
  } as { [key: string]: string };

  // eslint-disable-next-line react/display-name
  const CustomToggle = React.forwardRef(
    ({ children, onClick }: { children: React.ReactNode; onClick: (e: any) => void }, ref: any) => (
      <div
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        className="lngSelectorWrapper tw-p-2 tw-rounded-full tw-bg-[#19223F] tw-relative tw-flex tw-items-center"
      >
        <Image
          src={lngFlagMapper[params.lang as string]}
          alt={params.lang as string}
          width={28}
          height={28}
          className="tw-rounded-full"
          title={(params.lang as string) + ' flag'}
        />
        <BiCaretDown size={16} className="tw-ml-2" />
      </div>
    )
  );

  return (
    <Dropdown placement={placement}>
      <Dropdown.Toggle as={CustomToggle} id={id} />
      <Dropdown.Menu>
        {i18nConfig.locales.map((l) => (
          <Dropdown.Item key={l} as={'span'}>
            <span
              onClick={() => {
                handleClick(selectHandler(l));
              }}
              className="d-block tw-cursor-pointer"
            >
              {LanguageMapper[l]}
            </span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageChangeDropdown;
